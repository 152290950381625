import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Iconify from "../Iconify";
import PropTypes from "prop-types";
import { TASK_STATUS_OPTIONS } from "src/constants";
// ---------------------------------------------------------
const icon_props = {
  width: "1.3rem",
  height: "1.3rem",
};
const menu_icon_props = {
  width: "1rem",
  height: "1rem",
};
// ---------------------------------------------------------
const StatusPopover = ({
  value,
  allowOpen,
  onChange,
  excludedOptions,
  extraStatus = [],
  width = "1.9rem",
  height = "1.9rem",
}) => {
  const [open, setOpen] = useState(null);
  // ---------------------------------------------------------
  const handleOpen = (event) => {
    if (!allowOpen) {
      console.log("not allowed");
      return;
    }
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleClick = (_option, _index) => {
    console.log(_option, _index);
    onChange(_option, _index);
    setOpen(null);
  };
  const getSelectedColor = () => {
    let [target] = TASK_STATUS_OPTIONS.concat(
      extraStatus.map((status) => {
        return {
          label: status.title,
          value: status.value,
          color: status.color_code,
          lookup: status.title,
          option_type: "status",
        };
      })
    ).filter((item) => item.value === value);
    if (target) {
      return target.color;
    }
    return "#969292";
  };
  const getTitle = () => {
    let [target] = TASK_STATUS_OPTIONS.concat(
      extraStatus.map((status) => {
        return {
          label: status.title,
          value: status.value,
          color: status.color_code,
          lookup: status.title,
          option_type: "status",
        };
      })
    ).filter((item) => item.value === value);
    if (target) {
      return "Status" + " " + target.label;
    }
    return "Status";
  };
  const filterOptions = (_options) => {
    console.log(_options,"_options")
    console.log(excludedOptions,"_excludedOptions")
    console.log(value,"_value")
    if (!excludedOptions) {
      return _options;
    }
    if (excludedOptions) {
      const filtered = _options.filter((item) => {
        let [exist] = excludedOptions.filter(
          (_item) => String(_item) === String(item.value)
        );
        if (!exist) {
          return item;
        }
      });
      return filtered.concat(
        extraStatus.map((status) => {
          return {
            label: status.title,
            value: status.value,
            color: status.color_code,
            lookup: status.title,
            option_type: "status",
          };
        })
      );
    }
  };
  // ---------------------------------------------------------
  return (
    <div>
      <Tooltip title={getTitle()}>
        <IconButton
          sx={{
            bgcolor: (theme) => theme.palette.action.hover,
            width: width,
            height: height,
          }}
          onClick={handleOpen}
          size="small"
        >
          <Iconify
            sx={{ ...icon_props, color: getSelectedColor() }}
            icon="carbon:dot-mark"
          />
        </IconButton>
      </Tooltip>
      <Menu
        disableEnforceFocus
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ mt: 1 }}
      >
        {filterOptions(TASK_STATUS_OPTIONS).map((option, index) => (
          <MenuItem
            key={index}
            selected={value === option.value}
            onClick={() => handleClick(option, index)}
            sx={{ typography: "body2", px: 1 }}
          >
            <Iconify
              sx={{ ...menu_icon_props, color: option.color, mr: 1 }}
              icon="carbon:dot-mark"
            />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default StatusPopover;
StatusPopover.propTypes = {
  value: PropTypes.number.isRequired,
  allowOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
